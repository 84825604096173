<template>
  <div>
    <v-card>
      <v-container>
        <v-row>
          <h3 class="mt-3 ml-6">REPORTES</h3>
          <v-spacer> </v-spacer>
        </v-row>

        <v-row>
          <v-col class="pb-0">
            <v-divider></v-divider>
          </v-col>
        </v-row>

        <v-row>
          <!--
          <v-col cols="12" lg="6" md="6" class="pt-0">
            <v-list>
              <v-list-item>
                <v-list-item-avatar>
                  <v-btn
                    fab
                    @click="
                      exportExcel(1,'Reporte de sucesos del Área de Selección y Calibrado')"
                    >
                      <v-icon>mdi-cloud-download</v-icon>
                    </v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    ><b><a @click="exportExcel(1,'Reporte de sucesos del Área de Selección y Calibrado')"
                        style="color: black">
                        Reporte de sucesos del Área de Selección y Calibrado</a>
                    </b>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-avatar>
                  <v-btn
                    fab
                    @click="exportExcel(2, 'Reporte de Descarte Por Lote Producción')"
                    ><v-icon>mdi-cloud-download</v-icon></v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    ><b><a @click="exportExcel(2,'Reporte de Descarte Por Lote Producción')"
                        style="color: black">
                        Reporte de Descarte Por Lote Producción</a>
                    </b>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-avatar>
                  <v-btn
                    fab
                    @click="exportExcel(3, 'Reporte de Descarte Por Procedencia')"
                    ><v-icon>mdi-cloud-download</v-icon>
                  </v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    ><b><a
                        @click="exportExcel(3, 'Reporte de Descarte Por Procedencia')"
                        style="color: black">
                        Reporte de Descarte Por Procedencia</a>
                    </b>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

               <v-list-item>
                <v-list-item-avatar>
                  <v-btn
                    fab
                    @click="exportExcel(7, 'Reporte de Descarte Por Procedencia')"
                    ><v-icon>mdi-cloud-download</v-icon>
                  </v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    ><b><a
                        @click="exportExcel(7, 'Reporte de calibrado')"
                        style="color: black">
                        Reporte de Calibrado</a>
                    </b>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

                <v-list-item>
                <v-list-item-avatar>
                  <v-btn
                    fab @click="exportExcel(4,'Reporte de Calibres Diario por Procedencia')"
                    ><v-icon>mdi-cloud-download</v-icon>
                  </v-btn>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    ><b><a @click="exportExcel(4,'Reporte de Calibres Diario por Procedencia')"
                        style="color: black">
                        Reporte de Calibres Diario por Procedencia</a>
                    </b
                  ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-avatar>
                  <v-btn
                    fab
                    @click="exportExcel(5,'Reporte de Calibres - Lote Producción x Productor')"
                    ><v-icon>mdi-cloud-download</v-icon>
                  </v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title
                    ><b><a @click="exportExcel(5,'Reporte de Calibres - Lote Producción x Productor')"
                        style="color: black">
                        Reporte de Calibres - Lote Producción x Productor</a
                      ></b
                    ></v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                <v-list-item-avatar>
                  <v-btn
                    fab @click="exportExcel(6, 'Reporte de Calibres x Productor')"
                    ><v-icon>mdi-cloud-download</v-icon>
                  </v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <b><a @click="exportExcel(6, 'Reporte de Calibres x Productor')"
                        style="color: black">
                        Reporte de Calibres x Productor</a>
                    </b>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

              <v-list-item>
                
              </v-list-item>
            </v-list>
          </v-col>
-->
          <v-col cols="12" lg="6" md="6" class="pt-0">
            <v-list>
            

                <v-list-item>
                <v-list-item-avatar>
                  <v-btn
                    fab @click="exportExcel(7, 'EJERCICIO DE TRAZABILIDAD - CALIBRADO')"
                    ><v-icon>mdi-cloud-download</v-icon>
                  </v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <b><a @click="exportExcel(7, 'EJERCICIO DE TRAZABILIDAD - CALIBRADO')"
                        style="color: black">
                        EJERCICIO DE TRAZABILIDAD - CALIBRADO</a>
                    </b>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

               <v-list-item>
                <v-list-item-avatar>
                  <v-btn
                    fab @click="exportExcel(8, 'AP-FR-059 (RESUMEN DE REPORTE DE CALIBRADO).')"
                    ><v-icon>mdi-cloud-download</v-icon>
                  </v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <b><a @click="exportExcel(8, 'AP-FR-059 (RESUMEN DE REPORTE DE CALIBRADO).')"
                        style="color: black">
                        AP-FR-059 (RESUMEN DE REPORTE DE CALIBRADO).</a>
                    </b>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

               <v-list-item>
                <v-list-item-avatar>
                  <v-btn
                    fab @click="exportExcel(9, 'AP-FR-055 (REGISTRO DE LOS MOTIVOS DE DESCARTE DE FRUTA EN EL ÁREA DE SELECCIÓN Y CALIBRADO).')"
                    ><v-icon>mdi-cloud-download</v-icon>
                  </v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <b><a @click="exportExcel(9, 'AP-FR-055 (REGISTRO DE LOS MOTIVOS DE DESCARTE DE FRUTA EN EL ÁREA DE SELECCIÓN Y CALIBRADO).')"
                        style="color: black">
                        AP-FR-055 (REGISTRO DE LOS MOTIVOS DE DESCARTE DE FRUTA EN EL ÁREA DE SELECCIÓN Y CALIBRADO).</a>
                    </b>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>

               <v-list-item>
                <v-list-item-avatar>
                  <v-btn
                    fab @click="exportExcel(10, 'BD CALIBRADO - DISTRIBUCION POR CALIBRE - POR LOTE')"
                    ><v-icon>mdi-cloud-download</v-icon>
                  </v-btn>
                </v-list-item-avatar>
                <v-list-item-content>
                  <v-list-item-title>
                    <b><a @click="exportExcel(10, 'BD CALIBRADO - DISTRIBUCION POR CALIBRE - POR LOTE')"
                        style="color: black">
                        BD CALIBRADO - DISTRIBUCION POR CALIBRE - POR LOTE</a>
                    </b>
                  </v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-col>
          
       </v-row>
      </v-container>
    </v-card>

    <v-dialog
      v-model="dialogDownload"
      v-if="dialogDownload"
      width="600"
    >
      <v-card>
          <v-container>
              <v-row>
                  <s-toolbar
                      label="Descargar Reporte"
                      excel
                      @excel="ReportDownloadExcel"
                      pdf
                      @pdf="ReportDownloadPDF">
                  </s-toolbar>
              </v-row>
              <v-row v-if="isCultive" >
                <v-col cols="6">
                  <s-select-definition
                   label="Tipo Cultivo"
                   v-model="filter.TypeCrop"
                   :def="1172">
                  </s-select-definition>
                </v-col>
              </v-row>
              <v-row>
              
                  <v-col cols="6">
                    <s-date 
                    label="Fecha Inicio"
                    v-model="filter.cDateInitial"
                    ></s-date>
                  </v-col>
                  <v-col cols="6">
                    <s-date 
                      label="Fecha Fin"
                      v-model="filter.cDateFin"
                    ></s-date>
                  </v-col>
              </v-row>
          </v-container>
      </v-card>
    </v-dialog>

  </div>
</template>

<script>
import _sLotProdCalibratedService from "../../../services/FreshProduction/PrfLotProductionCalibratedService";
import _sQryConfigurationService from "@/services/QueryManager/QryConfigurationService.js";

export default {
  data() {
    return {
      isCultive : false,
      filter: {},
      items: {},
      dialogDownload:false,
      xFlag: 0,
      NameReport: "",
      processing:false,
      report: {},
      
    };
  },
  created() {
    this.initialize();
  },

  methods: {
    initialize() {
      /* this.filter.xFlag = this.xFlag;
      _sLotProdCalibratedService
        .getReportAllCalibrated(this.filter, this.$fun.getUserID())
        .then((resp) => {
          if (resp.status == 200) {
            this.items = resp.data;
            console.log("items report", this.items);
          }
        }); */
    },

    exportExcel(xFlag, NameReport) {
      /* this.$fun.alert("¿Seguro de descargar?", "question")
      .then(r => {
        if(r.value){ */
          this.dialogDownload = true;
          this.isCultive = (xFlag == 8) ? true : false;
          this.xFlag = xFlag;
          this.NameReport = NameReport;
        /* }
      }) */
  
      /* if (this.items !== null) {
        _sLotProdCalibratedService
          .exportExcelReport(
            this.items,
            xFlag,
            NameReport,
            this.$fun.getUserID()
          )
          .then((r) => {
            if (r.status == 200) {
              this.$fun.downloadFile(
                r.data,
                this.$const.TypeFile.EXCEL,
                this.$fun.getUserID()
              );
            }
          });
      } */
    },

    ReportDownloadExcel()
    {

      if (this.xFlag == 7) {
         
        let parametervalue = [];

        parametervalue.push(this.filter.cDateInitial)
        parametervalue.push(this.filter.cDateFin)
        parametervalue.push("2")
        let parametergen = " @dateBegin,@dateEnd ,@typeCrop";
        this.downloadReport("REPORTE EJERCICIO DE TRAZABILIDAD - CALIBRADO","REPORT_EXERCISE_TRACEABILITY_CALIBRATED",parametervalue,parametergen);


      }else if(this.xFlag == 8){
        let parametervalue = [];


        parametervalue.push(this.filter.TypeCrop);
        parametervalue.push(this.filter.cDateInitial);
        parametervalue.push(this.filter.cDateFin);
       
        let parametergen = "@TypeCrop,@dateBegin,@dateEnd";
        this.downloadReport("AP-FR-059 (RESUMEN DE REPORTE DE CALIBRADO)","REPORT_CALIBRATED_RESUME",parametervalue,parametergen);

      }
      else if(this.xFlag == 9){
        let parametervalue = [];

        parametervalue.push(this.filter.cDateInitial);
        parametervalue.push(this.filter.cDateFin);
       
        let parametergen = " @dateBegin,@dateEnd ";
        this.downloadReport("AP-FR-055 (REGISTRO DE LOS MOTIVOS DE DESCARTE DE FRUTA EN EL ÁREA DE SELECCIÓN Y CALIBRADO).","REPOR_CALIBRATED_REASON_FOR_DISCARDING_FRUIT",parametervalue,parametergen);

      }
      else if(this.xFlag == 10){
        let parametervalue = [];

        parametervalue.push(this.filter.cDateInitial);
        parametervalue.push(this.filter.cDateFin);
       
        let parametergen = " @dateBegin,@dateEnd ";
        this.downloadReport("BD CALIBRADO - DISTRIBUCION POR CALIBRE - POR LOTE","REPORT_CALIBRATED_BD_CALIBER_LOTE",parametervalue,parametergen);

      }
      else{
      
      this.filter.xFlag = this.xFlag;
      _sLotProdCalibratedService
        .getReportAllCalibrated(this.filter, this.$fun.getUserID())
        .then((resp) => {
          if (resp.status == 200) {
            this.items = resp.data;

            if (this.items !== null) {
            _sLotProdCalibratedService
              .exportExcelReport(
                this.items,
                this.xFlag,
                this.NameReport,
                this.$fun.getUserID()
              )
              .then((r) => {
                if (r.status == 200) {
                  this.$fun.downloadFile(
                    r.data,
                    this.$const.TypeFile.EXCEL,
                    this.$fun.getUserID()
                  );
                }
              });
          }

          }
        });
  
      }

      
    },

    downloadDocumentExcel(){
      let obj = {
        DateBegin: new Date(),
        DateEnd:new Date(),
        TrnID:''
      }
      _sLotProdCalibratedService.downloadCalibrateExcel(obj, this.$fun.getUserID())
      .then((r)=>{
        if(r.status ==200){
          this.$fun.downloadFile(
            r.data,
            this.$const.TypeFile.EXCEL,
            "Reporte de Hidrotermico" )
        }
      })
    },

    downloadDocumentPdf(){

    },

    ReportDownloadPDF()
    {
      if(this.xFlag == 8){
         
        let obj = {};
        obj.Flag = this.xFlag;"",""
        obj.NameReport = "AP-FR-059 (RESUMEN DE REPORTE DE CALIBRADO)"; 
        obj.NameProc = "REPORT_CALIBRATED_RESUME";
        obj.DateBegin = this.filter.cDateInitial;
        obj.DateEnd = this.filter.cDateFin;
        obj.TypeCultivo = this.filter.TypeCultivo;


        console.log("x filter", obj);
         
          _sLotProdCalibratedService
            .reportCalibratePDF(obj, this.$fun.getUserID())
            .then((r) => {

                 this.$fun.downloadFile(
                    r.data,
                    this.$const.TypeFile.PDF,
                    obj.NameReport
                    );
              
            });
      }

      if(this.xFlag == 9){
         
        let obj = {};
        obj.Flag = this.xFlag;
        obj.NameReport = "AP-FR-055 (REGISTRO DE LOS MOTIVOS DE DESCARTE DE FRUTA EN EL ÁREA DE SELECCIÓN Y CALIBRADO)."; 
        obj.NameProc = "REPOR_CALIBRATED_REASON_FOR_DISCARDING_FRUIT";
        obj.DateBegin = this.filter.cDateInitial;
        obj.DateEnd = this.filter.cDateFin;


        console.log("x filter", obj);
         
          _sLotProdCalibratedService
            .reportCalibratePDF(obj, this.$fun.getUserID())
            .then((r) => {

                 this.$fun.downloadFile(
                    r.data,
                    this.$const.TypeFile.PDF,
                    obj.NameReport
                    );
              
            });
      }
    },
    	downloadReport(QryNameReport,qryname,parametervalue,parametergen) {
				 console.log("QryNameReport",QryNameReport);

				this.report.QryNameReport = QryNameReport	;  //this.DateBegin + "," + this.DateEnd + "," + this.number;
				this.report.QryParamsArray = parametergen	;  //this.DateBegin + "," + this.DateEnd + "," + this.number;
				this.report.QryParamsArrayValue = parametervalue.join(","); //this.DateBegin + "," + this.DateEnd + "," + this.number;
				this.report.QryNameProcedure = qryname;
					//this.report.QryDatabaseNameName + ".." + this.report.QryNameSp;

				this.processing = true;
				_sQryConfigurationService
					.downloadexcel(this.report, this.$fun.getUserID())
					.then(
						(r) => {
							if (r.status == 200) {
								this.processing = false;

								this.$fun.downloadFile(
									r.data,
									this.$const.TypeFile.EXCEL,
									 QryNameReport
								);
							}
						},
						(e) => {
							//this.messageProcessing = e.response.data.Message;
							this.processing = true;
						}
					);

			 
			},

  },
};
</script>

<style>
</style>